import 'Node/bootstrap';
import 'Node/jquery-match-height';



import ScrollReveal from 'Node/scrollreveal'


// navigation imports
import { offCanvasNavigation } from "./_modules/navigation/_offCanvasNav.js"
import { shrinkNav } from "./_modules/navigation/_shrink-nav.js"


const $ = require('Node/jquery');
const markNavOpened = ( () => {

    const $target = $('.main-navigation-wrapper .dropdown')

    var toggleNavOpened = (el) =>{
        $(el.relatedTarget).parent().parent().toggleClass("opened") 
    }

    $target.on('hide.bs.dropdown', toggleNavOpened );
    $target.on('show.bs.dropdown', toggleNavOpened );
    
})();


// Init functions
// const MainNav = new offCanvasNavigation;

ScrollReveal().reveal('.reveal', {
    distance: '40px',
    easeing: 'ease-in-out',

    viewOffset : {
        top: 140
    }
});


